<template>
  <xt_hcontainer class="_headline">
    <xt_vcontainer
      @mouseover="firstArticleMouseProc(true)"
      @mouseleave="firstArticleMouseProc(false)"
      @touchend="firstArticleMouseProc(false)"
      class="first_article_container col-esm-4 col-sm1-8 col-sm2-6 col-lg-8"
    >
      <xt_imgcard
        class="first_artcile_imgcard"
        style="z-index: 1"
        :scale="imgScale"
        :src="require('@/assets/imgs/placeholder/image.svg')"
      />
      <xt_article_card_brief
        :parentHover="hover"
        clickable
        category="Category"
        title="XtTech Blog will release soon"
        style="z-index: 5"
        class="_maindesc"
      ></xt_article_card_brief>
    </xt_vcontainer>
    <xt_vcontainer
      gutter_row="0px"
      class="flexContainer col-esm-6 col-sm1-8 col-sm2-6 col-lg-4"
    >
      <xt_article_card_brief
        class="headline_article_brief_card"
        category="Category"
        clickable
        title="XtTech Blog will release soon"
        :imgSrc="require('@/assets/imgs/placeholder/image.svg')"
      ></xt_article_card_brief>

      <xt_article_card_brief
        v-for="i in (1, 5)"
        :key="i"
        class="headline_article_brief_card"
        clickable
        category="Category"
        title="XtTech Blog will release soon"
        :imgSrc="require('@/assets/imgs/placeholder/image.svg')"
      ></xt_article_card_brief>
    </xt_vcontainer>
  </xt_hcontainer>
</template>

<script setup>
import xt_article_card_brief from "@/components/card/xt_article_card_brief.vue";
import xt_hcontainer from "@/components/container/xt_hcontainer.vue";
import xt_vcontainer from "@/components/container/xt_vcontainer.vue";
import xt_imgcard from "@/components/card/xt_imgcard.vue";
import { defineProps, ref } from "vue";

defineProps({
  width: { type: String, default: "258px" },
  height: { type: String, default: "145px" },
});

// const props = defineProps({
//   image: String,
//   category: String,
//   title: String,
//   subTitle: String,
// });

const imgScale = ref(1.0);
const hover = ref(false);

function firstArticleMouseProc(mouseOver) {
  if (mouseOver) {
    imgScale.value = 1.05;
    hover.value = true;
  } else {
    imgScale.value = 1.0;
    hover.value = false;
  }
}
</script>

<style lang="scss">
@import "@/assets/css/materialcolor";

._maindesc ._article_card_category {
  //headline4
  @extend .headline5;
  margin-bottom: var(--layout-margin);
}
._maindesc ._article_card_title {
  //headline4
  @extend .headline5;
}

._maindesc._article_card_container{
  padding: var(--layout-margin);
}
</style>
<style scoped lang="scss">
._maindesc {
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.6);
  margin-top: -48px;
  margin-left: 48px;
  width: calc(100% - 48px);
  border-radius: 16px;
  z-index: 10;
  background-color: var(--md-sys-color-primary-container);
}
</style>
<style scoped>
._headline {
  width: 100%;
  align-items: flex-start;
}

/* Extra small (Phone) 0-599px*/
@media all and (max-width: 599px) {
  .first_article_container {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -40px;
  }

  .first_artcile_imgcard {
    border-radius: 0px;
  }

  ._maindesc {
    margin-left: 0px;
    width: calc(100% - 16px * 2);
    margin-left: 16px;
    margin-right: 16px;
  }
}

.headline_article_brief_card {
  margin-top: 16px;
}

.headline_article_brief_card {
  display: none;
}

.headline_article_brief_card:nth-child(-n + 3) {
  display: block;
}

/* Small1 (Tablet) 600-904px*/

@media all and (min-width: 600px) and (max-width: 904px) {
  .headline_article_brief_card {
    display: none;
  }

  .headline_article_brief_card:nth-child(-n + 3) {
    display: block;
  }

  .first_article_container {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -40px;
  }

  ._maindesc {
    margin-left: 0px;
    width: calc(100% - 16px * 2);
    margin-left: 16px;
    margin-right: 16px;
  }

  .first_artcile_imgcard {
    border-radius: 0px;
  }
}

/* Small2 (Tablet) 905-1239px*/

@media all and (min-width: 905px) and (max-width: 1239px) {
  .headline_article_brief_card {
    display: none;
  }

  .headline_article_brief_card:nth-child(-n + 2) {
    display: block;
  }

  .first_article_container {
    margin-left: -36px;
  }
}

/* Medium (Laptop) 1240-1439px*/
@media all and (min-width: 1240px) and (max-width: 1439px) {
  .headline_article_brief_card {
    display: none;
  }

  .headline_article_brief_card:nth-child(-n + 4) {
    display: block;
  }

  .first_article_container {
    margin-left: -36px;
  }
}

/* Large (Desktop) >1440px */
@media all and (min-width: 1440px) {
  .headline_article_brief_card {
    display: none;
  }

  .headline_article_brief_card:nth-child(-n + 4) {
    display: block;
  }
}

.flexContainer {
  overflow: hidden;
  flex-wrap: wrap;
}
</style>
