<template>
  <xt_card class="experience_card col-esm-4 col-sm1-8 col-lg-6">
    <xt_hcontainer nowrap class="experience_card_container_top">
      <div class="experience_position_container headline5 col-esm-4">{{ data.position }}</div>

      <xt_vcontainer class="experience_time_container headline6 col-esm-4" gutter_row="0px">
        <div>
          {{ generateDateText(data.startDate, data.endDate) }}
        </div>
        <div>
          {{ generateDurationText(data,data.startDate, data.endDate) }}
        </div>
      </xt_vcontainer>
    </xt_hcontainer>
    <div class="experience_org">{{ data.org }}</div>

    <div class="experience_jd" lang="en">
      {{ data.jd }}
    </div>
    <div class="education_logobtn_container">
      <xt_circular_btn v-for="orgDesc in data.orgDescs" :key="orgDesc.name" class="experience_card_org_logo"
        :imgSrc="orgDesc.logo" :imgDarkSrc="orgDesc.logo" :href="orgDesc.link"></xt_circular_btn>
    </div>

    <xt_spacer></xt_spacer>
  </xt_card>
</template>


<script setup>
import xt_vcontainer from "@/components/container/xt_vcontainer.vue";
import xt_hcontainer from "@/components/container/xt_hcontainer.vue";
import xt_card from "@/components/card/xt_card.vue";
import xt_spacer from "@/components/spacer/xt_spacer.vue";
import xt_circular_btn from "@/components/buttons/xt_circular_btn.vue";
import { defineProps } from "vue";

defineProps({
  /* eslint-disable */
  data: {
    type: Object,
    default: function () {
      return {
        position: "Position",
        startDate: new Date("12/01/2020"),
        endDate: null,
        org: "Org",
        orgDescs: [
          {
            name: "Org1 Name",
            logo: "",
            link: "Org2 Link",
          },
          {
            name: "Org1 Name",
            logo: "",
            link: "Org2 Link",
          },
        ],
        location: "Location",
        jd: "Job Description",
      };
    },
  },
});

function calcDate(srcDate) {
  var seconds = srcDate / 1000;
  const MS_IN_A_DAY = 60 * 60 * 24;
  const MS_IN_A_MONTH = 30 * MS_IN_A_DAY;
  const MS_IN_A_YEAR = 12 * MS_IN_A_MONTH;

  //eslint-disable-next-line
  var years = Math.floor(seconds / MS_IN_A_YEAR);
  seconds = seconds % MS_IN_A_YEAR;
  var months = Math.floor(seconds / MS_IN_A_MONTH);
  seconds = seconds % MS_IN_A_MONTH;
  var days = Math.floor(seconds / MS_IN_A_DAY);

  return [years, months, days];
}

function generateDateText(startDate, endDate) {
  if (startDate == null) {
    return "";
  }

  let isPresent = false;
  if (endDate == null) {
    isPresent = true;
    endDate = new Date();
  }
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let textList = [
    months[startDate.getMonth()],
    " ",
    startDate.getFullYear()
  ];

  if (endDate - startDate > 0) {
    textList.push(" - ");
    if (isPresent) {
      textList.push("Present");
    } else {
      textList = textList.concat([
        months[endDate.getMonth()],
        " ",
        endDate.getFullYear(),
      ]);
    }
  }

  return textList.join("");
}

function generateDurationText(data,startDate, endDate) {
  if (startDate == null) {
    return "";
  }
  if (endDate == null) {
    endDate = new Date();
  }
  let duration = endDate - startDate;

  let durList = calcDate(duration);

  if(durList[2]>15){
    durList[1]+=1;
    durList[2]=0;
  }

  let textList = [];
  if (durList[0] > 0) {
    textList = textList.concat([durList[0], " yr "]);
    textList = textList.concat([durList[1], " mos "]);
  } else if (durList[1] > 0) {
    textList = textList.concat([durList[1], " mos"]);
  } else if (durList[2] > 0) {
    textList = textList.concat([durList[2], " days"]);
  } else {
    textList = ["Incoming"];
  }
  return textList.join("");
}
</script>

<style scoped lang="scss">
.experience_time_container {
  flex-grow: 0;
}

.experience_org {
  text-align: right;
}

.experience_card {
  padding: var(--layout-margin);
  text-align: center;
  align-self: stretch;
  flex-grow: 0;
  border: 1px solid;
  border-color: var(--md-sys-color-outline);
  border-radius: 16px;
}

.experience_position_container{
  text-align: left;
}

.experience_org_logo_container {
  justify-content: end;
  column-gap: 0px;
}

.experience_logobtn_container {
  display: inline-flex;
  float: right;
}

.experience_jd {
  text-align: left;
  flex-grow: 99999;
  hyphens: auto;
  word-wrap: break-word;
  word-break: break-word;
}

.experience_card_container_top {
  diplay: flex;
  text-align: right;
  justify-content: space-between;
}

.experience_card_org_logo {
  width: 48px;
  height: 48px;
}

.education_logobtn_container {
  display: inline-flex;
  float: left;
}
</style>
