<template>
  <div class="_spacer"></div>
</template>

<script>
export default {
  name: "xt_spacer",
  components: {},
  props: ["direction"],
  computed: {},
};
</script>

<style scoped>
._spacer {
  flex-grow: 1;
}
</style>
