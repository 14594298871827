<template>
    <img :src="isDarkMode() ? imgDarkSrc : imgSrc" />
</template>

<script setup>
import { defineProps } from "vue";

defineProps({
  imgSrc: { type: String, default: "" },
  imgDarkSrc: { type: String, default: "" },
});

function isDarkMode() {
  return window.matchMedia("(prefers-color-scheme: dark)").matches;
}
</script>

<style scoped lang="scss">

</style>
