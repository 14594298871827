<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "xt_vcontainer",
};
</script>
<script setup>
import { defineProps } from "vue";
defineProps({
  gutter_col: { type: String, default: "0px" },
  gutter_row: { type: String, default: "24px" },
});
</script>



<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  row-gap: v-bind(gutter_row);
  column-gap: v-bind(gutter_col);
}
</style>