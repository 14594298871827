<template>
  <xt_vcontainer class="statuscontent" gutter_row="24px">
  <xt_spinner v-if="spinnervisibility" class='loadingSpinner'></xt_spinner>
  <iframe class="statusPage" v-show="isvisible" @load="iframeLoaded" src="https://status.xttechgroup.com"/>
  </xt_vcontainer>
</template>

<script setup>
import xt_vcontainer from "@/components/container/xt_vcontainer.vue";
import xt_spinner from "@/components/progressbar/xt_spinner.vue";

import {ref} from 'vue';

const isvisible=ref(false)
const spinnervisibility=ref(true)

function iframeLoaded(){
  setTimeout(()=> { spinnervisibility.value=false;isvisible.value=true;},500);
}

</script>

<script>


</script>

<style >
/* Include the global css */
@import "@/assets/css/materialcolor.css";

.content{
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  flex-grow: 999999;
}
</style>

<style lang="scss" scoped>

.statuscontent {
  width: 100%;
  flex-grow:1;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: center;
}

.statusPage{
  border: none;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  background-color: inherit;
}

.loadingSpinner{
  align-self: center;
  transition: opacity .5s;
}
</style>
