<template>
  <div class="container" v-bind:class="{ _nowarp: nowarp, _warp: !nowarp }">
    <slot></slot>
  <!-- <xt_circular_btn class="desktopScrollBtn" v-if="true"></xt_circular_btn> -->
  </div>
</template>

<script>
export default {
  name: "xt_hcontainer",
};
</script>
<script setup>
import { defineProps } from "vue";
defineProps({
  gutter_col: { type: String, default: "24px" },
  gutter_row: { type: String, default: "0px" },
  nowarp: { type: Boolean, default: false },
});


// function scrollHanlder(event){
//   const target=event.target;

// }
// onMounted(() => {

//   let elem=document.createElement("xt_circular_btn")
//   elem.classList.add('')

//   document.appendChild(elem);
//   window.addEventListener('scroll', scrollHanlder)
// });

// onBeforeUnmount(() => {
//    window.removeEventListener('scroll', scrollHanlder)
// });

</script>


<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  // flex-wrap: v-bind(warpParm);
  row-gap: v-bind(gutter_row);
  column-gap: v-bind(gutter_col);
}
._nowarp {
  flex-wrap: nowrap;
}

._warp {
  flex-wrap: wrap;
}
</style>

<!-- Width calculation -->

<style lang="scss">
/* Large (Desktop) >1440px */

@media all and (min-width: 1440px) {
  @for $i from 1 through 12 {
    //12 column for large

    .col-lg-#{$i} {
      width: calc(
        (100% - (v-bind(gutter_col) * (12 /#{$i} - 1))) / (12 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }
}

/* Small1 (Tablet) 600-904px*/

@media all and (min-width: 600px) and (max-width: 904px) {
  @for $i from 1 through 8 {
    //8 column for large

    .col-lg-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (8 /#{$i} - 1)) / (8 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }

  @for $i from 1 through 8 {
    //8 column for medium

    .col-md-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (8 /#{$i} - 1)) / (8 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }

  @for $i from 1 through 8 {
    //8 column for small2

    .col-sm2-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (8 /#{$i} - 1)) / (8 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }

  @for $i from 1 through 8 {
    //8 column for small1

    .col-sm1-#{$i} {
      width: calc(
        (100% - (v-bind(gutter_col) * (8 /#{$i} - 1))) / (8 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }
}

/* Small2 (Tablet) 905-1239px*/

@media all and (min-width: 905px) and (max-width: 1239px) {
  @for $i from 1 through 12 {
    //122 column for large

    .col-lg-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (12 /#{$i} - 1)) / (12 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }

  @for $i from 1 through 12 {
    //12 column for medium

    .col-md-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (12 /#{$i} - 1)) / (12 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }

  @for $i from 1 through 12 {
    //12 column for small2

    .col-sm2-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (12 /#{$i} - 1)) / (12 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }
  /* Medium (Laptop) 1240-1439px*/

  @media all and (min-width: 1240px) and (max-width: 1439px) {
    @for $i from 1 through 12 {
      //12 column for large

      .col-lg-#{$i} {
        width: calc(
          (100% - v-bind(gutter_col) * (12 /#{$i} - 1)) / (12 /#{$i})
        ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
        flex-grow: 1;
      }
    }

    @for $i from 1 through 12 {
      //12 column for mid

      .col-md-#{$i} {
        width: calc(
          (100% - v-bind(gutter_col) * (12 /#{$i} - 1)) / (12 /#{$i})
        ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
        flex-grow: 1;
      }
    }
  }
}

/* Medium (Laptop) 1240-1439px*/

@media all and (min-width: 1240px) and (max-width: 1439px) {
  @for $i from 1 through 12 {
    //12 column for large

    .container > .col-lg-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (12 /#{$i} - 1)) / (12 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }

  @for $i from 1 through 12 {
    //12 column for mid

    .container > .col-md-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (12 /#{$i} - 1)) / (12 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }
}

/* Extra small (Phone) 0-599px*/
@media all and (max-width: 599px) {
  @for $i from 1 through 4 {
    //8 column for large

    .container > .col-lg-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (4 /#{$i} - 1)) / (4 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }

  @for $i from 1 through 4 {
    //8 column for medium

    .container > .col-md-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (4 /#{$i} - 1)) / (4 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }

  @for $i from 1 through 4 {
    //8 column for small2

    .container > .col-sm2-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (4 /#{$i} - 1)) / (4 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }

  @for $i from 1 through 4 {
    //8 column for small1

    .container > .col-sm1-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (4 /#{$i} - 1)) / (4 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }

  @for $i from 1 through 4 {
    //8 column for small1

    .container > .col-esm-#{$i} {
      width: calc(
        (100% - v-bind(gutter_col) * (4 /#{$i} - 1)) / (4 /#{$i})
      ); // (Parent width - Total Gutter Width) / Total items that we can accomodate
      flex-grow: 1;
    }
  }
}
</style>

