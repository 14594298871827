<template>
  <xt_vcontainer
    class="_article_card_container"
    @mouseover="mouseEventProc(true)"
    @mouseleave="mouseEventProc(false)"
    @touchend="mouseEventProc(false)"
    :style="{
      cursor: hover || (parentHover && properties.clickable) ? 'pointer' : '',
    }"
    gutter_row="0px"
  >
    <xt_imgcard
      :scale="imgScale"
      class="_thumbnail_vertical"
      :src="imgSrc"
      v-if="imgSrc != ''"
    />
    <!-- Category -->
    <xt_rect_btn class="_article_card_category" v-if="category != ''">
      {{ category }}
    </xt_rect_btn>
    <!-- Title and icon -->
    <xt_hcontainer class="_article_card_detail_container" gutter_col="0px">
      <xt_vcontainer>
        <xt_rect_btn
          class="headline7 _article_card_title"
          v-bind:style="{
            textDecoration:
              hover || (parentHover && properties.clickable) ? 'underline' : '',
          }"
          v-if="title != ''"
        >
          {{ title }}
        </xt_rect_btn>
        <div class="_article_brief_details" v-if="details != ''">
          {{ details }}
        </div>
      </xt_vcontainer>

      <xt_imgcard
        :scale="imgScale"
        class="_thumbnail"
        :src="imgSrc"
        v-if="imgSrc != '' && !vertical"
      />
      <slot></slot>
    </xt_hcontainer>
  </xt_vcontainer>
</template>


<script setup>
import xt_hcontainer from "@/components/container/xt_hcontainer.vue";
import xt_vcontainer from "@/components/container/xt_vcontainer.vue";
import xt_imgcard from "@/components/card/xt_imgcard.vue";
import { defineProps, ref } from "vue";
import xt_rect_btn from "@/components/buttons/xt_rect_btn.vue";

const properties = defineProps({
  imgSrc: { type: String, default: "" },
  category: { type: String, default: "" },
  parentHover: { type: Boolean, default: false },
  title: { type: String, default: "" },
  details: { type: String, default: "" },
  clickable: { type: Boolean, default: null },
  vertical: { type: Boolean, default: null },
});

const hover = ref(null);
const imgScale = ref(1.0);
function mouseEventProc(mouseOver) {
  if (mouseOver && properties.clickable) {
    hover.value = true;
    imgScale.value = 1.1;
  } else {
    hover.value = false;
    imgScale.value = 1.0;
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/materialcolor.css";

._article_card_container {
  background-color: var(--md-sys-color-secondary-container);
  border-radius: 16px;
  padding: 16px;
  text-align: left;
}

._article_card_title {
  text-align: left;
  align-self: flex-start;
  display: inline-block;
}
._thumbnail_vertical {
  width: 100%;
  height: 30%;
  display: none;
}
._thumbnail {
  width: 160px;
  height: 90px;
  flex-grow: 0;
  flex-shrink: 0;
  /* margin: 16px; */
  margin-left: 8px;
  border-radius: 16px 16px 16px 16px;
}
/* Extra small (Phone) 0-599px*/
@media all and (max-width: 599px) {
  ._thumbnail {
    width: 90px;
    height: 90px;
  }
}

.imgzoom {
  transform: scale(1.5);
}

._thumbnail_vertical {
  border-radius: 16px;
}

._article_card_category {
  display: inline-block;
  margin-bottom: 0px;
  color: var(--md-sys-color-primary);
}
._article_card_container {
  border-radius: 8px;
  // justify-content: right;
  text-align: left;
  border-radius: 16px 16px 16px 16px;
}

._article_card_detail_container {
  align-items: top;
  flex-wrap: nowrap;

  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  justify-content: space-between;
}

._article_brief_details {
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.card {
  box-shadow: 0 0 32px rgb(0 0 0 / 11%);
  padding: 36px 24px;
}
</style>
