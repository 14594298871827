<template>
  <div
    @mouseover="mouseEventProc(true)"
    @mouseleave="mouseEventProc(false)"
    :class="{
      listItemFocus_nonclickable: focusNonClickable,
      listItemFocus_clickable: focusClickable,
      listItem: true,
    }"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";

const properties = defineProps({
  clickable: { type: Boolean, default: null },
});

var focusNonClickable = ref(false);
var focusClickable = ref(false);

function mouseEventProc(mouseOver) {
  if (properties.clickable !== null) {
    focusClickable.value = mouseOver;
  } else {
    focusNonClickable.value = mouseOver;
  }
}
</script>


<style scoped>
.listItem {
  padding: 10px;
  user-select: none;
}

.listItemFocus_nonclickable {
  background-color: var(--md-sys-color-secondary-container);
}

.listItemFocus_clickable {
  background-color: var(--md-sys-color-tertiary-container);
}
</style>;
