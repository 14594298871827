<template>
  <xt_ripple
    class="_circular_btn_background button"
    v-on:click="openLink"
    :clickable="href != null && href != ''"
  >
    <div class="_circular_btn_icon">
      <img :src="isDarkMode() ? imgDarkSrc : imgSrc" />
    </div>
  </xt_ripple>
</template>

<script setup>
import xt_ripple from "@/components/anim/xt_ripple.vue";
import { defineProps } from "vue";

const properties = defineProps({
  imgSrc: { type: String, default: "" },
  imgDarkSrc: { type: String, default: "" },
  href: { type: String, default: null },
  clickable: { type: Boolean, default: false },
});

function isDarkMode() {
  return window.matchMedia("(prefers-color-scheme: dark)").matches;
}

function openLink() {
  if (properties.href) {
    window.open(properties.href, "_blank");
  }
  return false;
}
</script>

<style scoped>
._circular_btn_icon {
  position: absolute;
  pointer-events: none;
  width: 70%;
  height: 70%;
}

img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
._circular_btn_background {
  border-radius: 50%;
}
</style>
