<template>
  <xt_vcontainer
    @mouseover="mouseEventProc(true)"
    @mouseleave="mouseEventProc(false)"
    @touchend="mouseEventProc(false)"
    class="_article_card_container"
    gutter_row="0px"
  >
    <div
      class="title-medium"
      v-bind:style="{ textDecoration: hover ? 'underline' : '' }"
    >
      <xt_rect_btn class="_article_card_title">XtTech Blog will release soon</xt_rect_btn>
    </div>
    <!-- Image -->
    <xt_imgcard
      class="_thumbnail"
      :scale="imgScale"
      :src="require('@/assets/imgs/placeholder/image.svg')"
    ></xt_imgcard>
  </xt_vcontainer>
</template>

<script>
import xt_vcontainer from "@/components/container/xt_vcontainer.vue";
import xt_imgcard from "@/components/card/xt_imgcard.vue";
import xt_rect_btn from "@/components/buttons/xt_rect_btn.vue";


export default {
  components: { xt_vcontainer },
  name: "xt_article_card",
};
</script>

<script setup>
import { defineProps, ref } from "vue";

const properties=defineProps({
  width: { type: String, default: "258px" },
  height: { type: String, default: "145px" },
  clickable: { type: Boolean, default: null }
});

const hover = ref(null);
const imgScale = ref(1.0);
function mouseEventProc(mouseOver) {
  if (mouseOver && properties.clickable) {
    hover.value = true;
    imgScale.value = 1.1;
  } else {
    hover.value = false;
    imgScale.value = 1.0;
  }
}
</script>


<style scoped lang="scss">
._thumbnail {
  margin-top: 16px;
  width: v-bind(width);
  height: height(height);
}
._article_card_container {
  text-align: left;
  align-items: stretch;
  background-color: var(--md-sys-color-surface);
}

._article_card_title {
  width: v-bind(width);
  text-align: left;
  display:inline-block;
}
</style>
