<template>
  <div class="hscrollRoot">
    <span ref="scrollRoot" class="makeSpan">
      <slot></slot>
    </span>
    <Xt_circular_btn
      clickable
      v-if="shouldShowLeft"
      class="fixedBtn1"
      :imgSrc="require('@/assets/imgs/icon/chevron-left-light.svg')"
      :imgDarkSrc="require('@/assets/imgs/icon/chevron-left-dark.svg')"
      @click="scrollContainer(0, -20, 300)"
    ></Xt_circular_btn>

    <Xt_circular_btn
      clickable
      class="fixedBtn2"
      :imgSrc="require('@/assets/imgs/icon/chevron-right-light.svg')"
      :imgDarkSrc="require('@/assets/imgs/icon/chevron-right-dark.svg')"
      v-if="shouldShowRight"
      @click="scrollContainer(0, 20, 300)"
    ></Xt_circular_btn>
  </div>
</template>


<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import Xt_circular_btn from "../buttons/xt_circular_btn.vue";
var shouldShowLeft = ref(false);
var shouldShowRight = ref(false);
const scrollRoot = ref(null);
let scrollTarget = null;

onMounted(() => {
  scrollTarget = scrollRoot.value.children[0];
  if (scrollTarget.scrollWidth > scrollTarget.clientWidth) {
    shouldShowRight.value = true;
  } else {
    shouldShowRight.value = false;
  }
});

function scrollContainer(start, end, duration) {
  const startTime = Date.now();
  const delta = end - start;

  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;
  let rafId;
  function tick() {
    const elapsed = Date.now() - startTime;
    const value = start + delta * Math.min(1, elapsed / duration);
    scrollTarget.scrollLeft += value;

    if (elapsed < duration) {
      rafId = requestAnimationFrame(tick);
    } else {
      rafId = undefined;
    }
  }
  requestAnimationFrame(tick);

  cancelAnimationFrame(rafId);
}

function scrollHanlder() {
  if (scrollTarget.scrollLeft > 0) {
    shouldShowLeft.value = true;
  } else {
    shouldShowLeft.value = false;
  }
  if (scrollTarget.clientWidth == scrollTarget.scrollWidth-scrollTarget.scrollLeft) {
    shouldShowRight.value = false;
  } else {
    shouldShowRight.value = true;
  }
}


onMounted(() => {
  scrollTarget.addEventListener("scroll", scrollHanlder);

});

onBeforeUnmount(() => {
  scrollTarget.removeEventListener("scroll", scrollHanlder);
});



</script>


<style scoped lang="scss">
.hscrollRoot {
  display: grid;
  grid-template-columns: 1fr 48px;
  position: relative;
}

.makeSpan {
  widows: 100%;
  overflow: auto;
  margin-right: -48px;
}

.fixedBtn1 {
  padding: relative;
  width: 48px;
  height: 48px;
  position: absolute;
  text-align: center;
  top: calc(50% - 24px);
  left: 0%;
  background: transparent;
}
.fixedBtn2 {
  padding: relative;
  width: 48px;
  height: 48px;
  position: absolute;
  text-align: center;
  top: calc(50% - 24px);
  left: calc(100% - 48px);
  background: transparent;
}



/* Extra small (Phone) 0-599px*/
/* Small1 (Tablet) 600-904px*/
@media all and (max-width: 904px) {
  .fixedBtn1{
    opacity: 0;
    pointer-events: none;
  }
  .fixedBtn2{
    opacity: 0;
    pointer-events: none;
  }
}

</style>;
