<template>
  <xt_vcontainer>
    <xt_vcontainer class="article_title_container" gutter_row="0px">
      <xt_rect_btn class="article_category headline4">Category</xt_rect_btn>
      <div class="_article_title headline1">Title</div>
      <xt_hcontainer class="_article_metadata_container">
        <div class="text_left">Jul 21, 2022</div>
        <div class="text_left">xx min read</div>
      </xt_hcontainer>
      <xt_imgcard
        class="_article_headline_image"
        :src="require('@/assets/imgs/placeholder/image.svg')"
      ></xt_imgcard>
    </xt_vcontainer>
    <span class="_article_content" v-html="rawHtml"> </span>
  </xt_vcontainer>
</template>

<script setup>
import { ref, onMounted } from "vue";
import xt_vcontainer from "@/components/container/xt_vcontainer.vue";
import xt_hcontainer from "@/components/container/xt_hcontainer.vue";
import xt_rect_btn from "@/components/buttons/xt_rect_btn.vue";
import xt_imgcard from "@/components/card/xt_imgcard.vue";

import axios from "axios";
import MarkdownIt from "markdown-it";
import { useRoute } from 'vue-router'
const route = useRoute()
var md = new MarkdownIt();
const rawHtml = ref("1234");
onMounted(() => {
  axios.get("/article/markdown/"+route.params['articleId']+".md").then((response) => {
    rawHtml.value = md.render(response.data);
  });
});
</script>


<style lang="scss">
@import "@/assets/css/materialcolor.css";

._article_container {
  justify-content: left;
  text-align: left;
}

.inline_block {
  display: inline-block;
}
.article_category {
  margin-bottom: var(--layout-margin);
  display: inline-block;
  text-align: left;
  align-self: left;
}

._article_metadata_container {
  margin-bottom: var(--layout-margin);
}
._article_content {
  max-width: min(570px, 100%);
  width: min(570px, 100%);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-direction: columns;
  align-self: center;
  text-align: left;
}

.article_title_container {
  width: max(70%,576px);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-direction: columns;
  align-items: flex-start;
  align-self: center;
  text-align: left;
}

._article_content p img {
  width: 100%;
  align-self: center;
}

._article_content pre,
._article_content blockquote {
  overflow: auto;
}

._article_content > table {
  align-self: center;
}

._article_title {
  align-self: left;
  text-align: left;
}

@media all and (max-width: 904px) {
  .article_title_container {
    width: 100%;
  }

  .article_content {
    width: 100%;
  }
}
</style>

