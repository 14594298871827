<template>
  <select class="xt_select_container">
    <slot></slot>
  </select>
</template>

<script setup>
</script>



<style scoped>
.xt_select_container{
  border: 0px solid;
  border-radius: 16px;
  padding: 0.25em 0.5em;
  background: transparent;
  font-size: inherit;
  cursor: pointer;
  line-height: 1.1;
}

.xt_select_container > option {
  display: block;
}
</style>
