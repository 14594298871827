<template>
  <div>
    <img class="round_corner" :style="transformStyle" :src="src" />
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";

const properties = defineProps({
  src: { type: String, default: "" },
  scale: { type: Number, default: 1.0 },
  activeColor: { type: String, default: "red" },
});

const transformStyle = computed(() => {
  return { transform: "scale(" + properties.scale + ")" };
});
</script>

<style scoped lang="scss">
img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  transition: transform 300ms,
}

div {
  display:inline-block;
  overflow: hidden;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  background-color: var(--md-sys-color-surface);
}
</style>
