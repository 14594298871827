<template>
  <xt_hcontainer gutter_col="0px" class="_container navbar">
    <xt_circular_btn
      class="btn_action_menu"
      :imgSrc="require('@/assets/imgs/icon/menu-light.svg')"
      :imgDarkSrc="require('@/assets/imgs/icon/menu-dark.svg')"
      id="navActionBtn"
      v-on:click="actionBtnClick"
    ></xt_circular_btn>

    <xt_img
      class="_navbar_logo"
      :imgSrc="require('@/assets/imgs/logo/logo_color.svg')"
      :imgDarkSrc="require('@/assets/imgs/logo/logo_gray_colorful.svg')"
    ></xt_img>
    <div>
      <slot name="xt_navbar_title"></slot>
    </div>
    <xt_hcontainer class="navbar_left_container" gutter_col="0px">
      <xt_rect_btn
        id="navMenuItem"
        class="navbutton"
        v-for="menuItem in menuItems"
        @click="menuItemClick(menuItem.url, false)"
        v-bind:class="{
          navItem_selectd: menuItem.selected,
          'title-medium': true,
        }"
        :key="menuItem.name"
      >
        {{ menuItem.name }}
      </xt_rect_btn>
    </xt_hcontainer>

    <xt_spacer></xt_spacer>
    <!-- <div id="navMenuItem">Search</div>
    <div id="navMenuItem">
      <slot name="xt_navbar_action_items"></slot>
    </div> -->
  </xt_hcontainer>
  <!-- Action Drawer -->
  <div class="_action_drawer" ref="action_drawer">
    <xt_vcontainer class="_action_drawer_container">
      <xt_hcontainer gutter_col="0px" class="_action_drawer_header">
        <xt_img
          class="_navbar_logo"
          :imgSrc="require('@/assets/imgs/logo/logo_gray.svg')"
          :imgDarkSrc="require('@/assets/imgs/logo/logo_gray.svg')"
        ></xt_img>
        <div>
          <slot name="xt_navbar_title"></slot>
        </div>
        <xt_spacer></xt_spacer>
        <xt_circular_btn
          class="btn_action_menu"
          :imgSrc="require('@/assets/imgs/icon/close-light.svg')"
          :imgDarkSrc="require('@/assets/imgs/icon/close-dark.svg')"
          v-on:click="actionCloseBtnClick"
        ></xt_circular_btn>
      </xt_hcontainer>
      <xt_rect_btn
        class="navbutton_actionbar"
        v-bind:class="{
          navItem_selectd: menuItem.selected,
          'title-medium': true,
        }"
        @click="menuItemClick(menuItem.url, true)"
        v-for="menuItem in menuItems"
        :key="menuItem"
      >
        {{ menuItem.name }}
      </xt_rect_btn>
    </xt_vcontainer>
    <div
      ref="action_drawer_shadow"
      class="_action_drawer_shadow"
      v-on:click="actionCloseBtnClick"
    ></div>
  </div>
</template>

<script setup>
import xt_spacer from "@/components/spacer/xt_spacer.vue";

import xt_hcontainer from "@/components/container/xt_hcontainer.vue";
import xt_vcontainer from "@/components/container/xt_vcontainer.vue";
import xt_rect_btn from "@/components/buttons/xt_rect_btn.vue";
import xt_circular_btn from "@/components/buttons/xt_circular_btn.vue";
import xt_img from "@/components/card/xt_img.vue";
import { router } from "@/router.js";

import { ref, onMounted } from "vue";


const action_drawer = ref(null);
const action_drawer_shadow = ref(null);

var menuItems = ref([
  {
    name: "Blog",
    selected: false,
    url: "/blog",
  },
  { name: "Lab", selected: false, url: "/lab" },
  { name: "Me", selected: false, url: "/me" },
  { name: "Status", selected: false, url: "/status" },
  // "Lab",
  // "Moments",
  // "Gallery",
  // "Tools",
  // "Dining",
  // { name: "Me", selected: false },
]);

function actionBtnClick() {
  // action_drawer.value.style.display = "block";
  //Disable scrolling
  action_drawer.value.style.visibility = "visible";
  action_drawer.value.style.left = "0vw";
  document.documentElement.style.overflow = "hidden";

  var listenerFunc = function (event) {
    if (event.propertyName == "left") {
      action_drawer_shadow.value.style.opacity = 1;
      action_drawer.value.removeEventListener("transitionend", listenerFunc);
    }
  };
  action_drawer.value.addEventListener("transitionend", listenerFunc);
}

function actionCloseBtnClick() {
  action_drawer_shadow.value.style.opacity = 0;

  document.documentElement.style.overflow = "auto";

  var opacityListenerFunc = function (event) {
    if (event.propertyName == "opacity") {
      action_drawer_shadow.value.removeEventListener(
        "transitionend",
        opacityListenerFunc
      );
      action_drawer.value.style.left = "-100vw";
    }
  };

  action_drawer_shadow.value.addEventListener(
    "transitionend",
    opacityListenerFunc
  );

  var leftListenerFunc = function (event) {
    if (event.propertyName == "left") {
      action_drawer.value.style.visibility = "hidden";
      action_drawer.value.removeEventListener(
        "transitionend",
        leftListenerFunc
      );
    }
  };
  action_drawer.value.addEventListener("transitionend", leftListenerFunc);
}

function menuItemClick(url, isActionBar) {
  router.push(url);
  if (isActionBar) {
    actionCloseBtnClick();
  }
}

onMounted(() => {
  router.beforeEach((to) => {
    if (to.name.startsWith("Blog")) {
      menuItems.value[0].selected = true;
      menuItems.value[1].selected = false;
      menuItems.value[2].selected = false;
      menuItems.value[3].selected = false;
    } else if (to.name.startsWith("Lab")) {
      menuItems.value[0].selected = false;
      menuItems.value[1].selected = true;
      menuItems.value[2].selected = false;
      menuItems.value[3].selected = false;
    } else if (to.name.startsWith("Me")) {
      menuItems.value[0].selected = false;
      menuItems.value[1].selected = false;
      menuItems.value[2].selected = true;
      menuItems.value[3].selected = false;
    } else if (to.name.startsWith("Status")) {
      menuItems.value[0].selected = false;
      menuItems.value[1].selected = false;
      menuItems.value[2].selected = false;
      menuItems.value[3].selected = true;

    }
    // ...
    // explicitly return false to cancel the navigation
    return true;
  });
});
</script>



<style scoped>
@import "@/assets/css/materialcolor.css";

._container {
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  min-height: 64px;
  max-height: 64px;
  z-index: 16;
}

.navbutton {
  text-align: center;
  height: 64px;
  line-height: 64px;
  padding-left: 32px;
  padding-right: 32px;
}

.navbutton_actionbar {
  height: 64px;
  line-height: 64px;
  padding-left: 0px;
}
.navbutton_actionbar:hover {
  background-color: var(--md-sys-color-tertiary-container);
}

.navItem_selectd {
  border-bottom: var(--md-sys-color-primary) solid;
}

.card {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 5px 0 rgb(0 0 0 / 23%);
}

._navbar_logo {
  height: 20px;
}

.navbar_left_container {
  height: 100%;
  text-align: center;
  margin-left: var(--layout-margin);
}

.btn_action_menu {
  width: 36px;
  height: 36px;
  margin-right: var(--layout-margin);
}

#navActionBtn {
  display: none;
}

/* Extra small (Phone) 0-599px*/
/* Small1 (Tablet) 600-904px*/

@media all and (max-width: 904px) {
  /* ._action_drawer {
    display: inherits;
  } */

  #navMenuItem {
    display: none;
  }

  #navActionBtn {
    display: inherit;
  }
}

._action_drawer {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: -100vw;
  top: 0px;
  z-index: 40;
  visibility: hidden;
  transition: left 300ms;
}
._action_drawer_container {
  width: calc(0.8 * 100vw);
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: left;
  text-align: left;
  background-color: var(--md-sys-color-surface);
}

._action_drawer_shadow {
  opacity: 0;
  position: absolute;
  left: calc(0.8 * 100vw);
  width: calc(0.2 * 100vw);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 200ms;
}

._action_drawer_header {
  flex-direction: row;
  width: 100%;
  align-items: center;
  min-height: 64px;
  max-height: 64px;
  border-bottom: 1px solid #dadce0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(218, 220, 224);
}
</style>
