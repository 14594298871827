<template>
  <div
    @mouseover="mouseEventProc(true)"
    @mouseleave="mouseEventProc(false)"
    class="xt_ripple_root"
    :style="{
      cursor: clickable ? 'pointer' : '',
    }"
    :class="{
      ripple_nonclickable: focusNonClickable,
      ripple_clickable: focusClickable,
    }"
    v-on:click="ripple_Animation"
  >
    <slot></slot>
    <span ref="circle"></span>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
const circle = ref(null);
const properties = defineProps({
  clickable: { type: Boolean, default: true },
});
function ripple_Animation(event) {
  if (properties.clickable) {
    const button = event.target;
    circle.value.classList.remove("xt_ripple_anim");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;
    circle.value.style.height = `${diameter}px`;
    circle.value.style.width = `${diameter}px`;
    circle.value.style.left = `${event.offsetX - radius}px`;
    circle.value.style.top = `${event.offsetY - radius}px`;
    circle.value.classList.add("xt_ripple_anim");
    button.addEventListener("animationend", function () {
      circle.value.classList.remove("xt_ripple_anim");
      button.removeEventListener("animationend", this);
    });
  }
}

var focusNonClickable = ref(false);
var focusClickable = ref(false);

function mouseEventProc(mouseOver) {
  if (properties.clickable !== null) {
    focusClickable.value = mouseOver;
  } else {
    focusNonClickable.value = mouseOver;
  }
}
</script>

<style scoped>
</style>
<style scoped>
.xt_ripple_root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  user-select: none;
}

span {
  position: absolute;
  pointer-events: none;
  z-index: 1000;
  background-color: transparent;
}

span.xt_ripple_anim {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  pointer-events: none;

  transform: scale(0);
  animation: ripple 300ms linear;
  background-color: var(--md-sys-color-on-surface);
}

.ripple_nonclickable {
  background-color: var(--md-sys-color-secondary-container);
}

.ripple_clickable {
  background-color: var(--md-sys-color-tertiary-container);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
</style>
