import { createRouter, createWebHistory } from 'vue-router'
import BlogArticle from '@/pages/Blog/BlogArticle.vue'
import BlogCategory from '@/pages/Blog/BlogCategory.vue'
import LabHome from '@/pages/Lab/LabHome.vue'
import BlogHome from '@/pages/Blog/BlogHome.vue'
import MeHome from '@/pages/Me/MeHome.vue'
import StatusHome from '@/pages/Status/StatusHome.vue'

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: MeHome,
            name:"Me-Home",
            children: [

            ],
        },
        {
            path: '/blog',
            name:"Blog",
            component: BlogHome,
            children: [

            ],

        },
        {
            path: '/blog/article/:articleId',
            name:"Blog-Article",
            component: BlogArticle,
            children: [

            ],
        },
        {
            path: '/blog/category',
            name:"Blog-Category",
            component: BlogCategory,
            children: [

            ],
        },
        {
            path: '/lab',
            name:"Lab",
            component: LabHome,
            children: [

            ],
        },
        {
            path: '/me',
            name:"Me",
            component: MeHome,
            children: [

            ],
        },
        {
            path: '/status',
            name:"Status",
            component: StatusHome,
            children: [

            ],
        },


    ],
})
