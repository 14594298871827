<template>
  <xt_ripple
    v-on:click="openLink"
    class="_rect_btn_background button"
    :clickable="href != null && href != ''"
  >
    <slot></slot>
  </xt_ripple>
</template>

<script setup>
import { defineProps } from "vue";
import xt_ripple from "@/components/anim/xt_ripple.vue";
const properties = defineProps({
  href: { type: String, default: null },
});

function openLink() {
  if (properties.href) {
    window.open(properties.href, "_blank");
  }
  return false;
}
</script>

<style scoped>
</style>
