<template>
  <xt_card class="col-esm-4 col-sm1-8 col-lg-12">
    <xt_vcontainer class="project_card_inner" gutter_row="0px">
      <span class="project_card_name headline5" lang="en">
        {{ data.name }}
        <span class="project_card_publisher body  col-esm-4"
          ><div class="headline6">{{ data.publisher }}</div>
          <div>
            {{ generateDateText(data.startDate, data.endDate) }}
          </div>
          <div>
            {{ generateDurationText(data.startDate, data.endDate) }}
          </div></span
        >
      </span>
      <div class="project_card_description" lang="en">
        {{ data.desc }}
      </div>
      <div class="project_card_authors_name">
        <span
          v-for="(author, index) in data.authors"
          :key="author.name"
          :style="{ fontWeight: author.isMe ? 'bold' : 'normal' }"
          >{{ author.name
          }}<span v-if="index != data.authors.length - 1">, </span>
        </span>
      </div>
      <xt_hcontainer>
        <xt_rect_btn class="_actionBtn" v-if="data.links.cite != null" :href="data.links.cite">Cite</xt_rect_btn>
        <xt_rect_btn class="_actionBtn" v-if="data.links.paper != null" :href="data.links.paper">Paper</xt_rect_btn>
        <xt_rect_btn class="_actionBtn" v-if="data.links.code != null" :href="data.links.code">Code</xt_rect_btn>
        <xt_rect_btn class="_actionBtn" v-if="data.links.talk != null" :href="data.links.talk">Talk</xt_rect_btn>
        <xt_rect_btn class="_actionBtn" v-if="data.links.url != null" :href="data.links.url">URL</xt_rect_btn>
      </xt_hcontainer>
    </xt_vcontainer>
  </xt_card>
</template>


<script setup>
import xt_vcontainer from "@/components/container/xt_vcontainer.vue";
import xt_hcontainer from "@/components/container/xt_hcontainer.vue";
import xt_card from "@/components/card/xt_card.vue";
import xt_rect_btn from "@/components/buttons/xt_rect_btn.vue";


import { defineProps } from "vue";

defineProps({
  /* eslint-disable */
  data: {
    type: Object,
    default: function () {
      return {
        name: "Project Name",
        desc: "Project Desc",
        authors: [
          { name: "Author1", isMe: false },
          { name: "Me", isMe: true },
        ],
        links: { paper: "https://localhost/paper" },
        publisher: "Publisher",
      };
    },
  },
});

function calcDate(srcDate) {
  var seconds = srcDate / 1000;
  const MS_IN_A_DAY = 60 * 60 * 24;
  const MS_IN_A_MONTH = 30 * MS_IN_A_DAY;
  const MS_IN_A_YEAR = 12 * MS_IN_A_MONTH;

  //eslint-disable-next-line
  var years = Math.floor(seconds / MS_IN_A_YEAR);
  seconds = seconds % MS_IN_A_YEAR;
  var months = Math.floor(seconds / MS_IN_A_MONTH);
  seconds = seconds % MS_IN_A_MONTH;
  var days = Math.floor(seconds / MS_IN_A_DAY);

  return [years, months, days];
}

function generateDateText(startDate, endDate) {
  if (startDate == null) {
    return "";
  }
  let isPresent = false;
  if (endDate == null) {
    isPresent = true;
    endDate = new Date();
  }
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let textList = [
    months[startDate.getMonth()],
    " ",
    startDate.getFullYear(),
    " - ",
  ];

  if (isPresent) {
    textList.push("Present");
  } else {
    textList = textList.concat([
      months[endDate.getMonth()],
      " ",
      endDate.getFullYear(),
    ]);
  }

  return textList.join("");
}

function generateDurationText(startDate, endDate) {
  if (startDate == null) {
    return "";
  }
  if (endDate == null) {
    endDate = new Date();
  }
  let duration = endDate - startDate;

  let durList = calcDate(duration);
  let textList = [];
  if (durList[0] > 0) {
    textList = textList.concat([durList[0], " yr "]);
    textList = textList.concat([durList[1], " mos "]);
  } else if (durList[1] > 0) {
    textList = textList.concat([durList[1], " mos"]);
  } else {
    textList = textList.concat([durList[2], " days"]);
  }
  return textList.join("");
}
</script>

<style scoped lang="scss">
.project_card_name {
  text-align: left;
  hyphens: auto;
  word-wrap: break-word;
  word-break: break-word;
}

.project_card_publisher {
  display: inline-flex;
  flex-direction: column;
  text-align: right;
  float: right;
}

.project_card_authors_name {
  text-align: left;
  hyphens: auto;
  overflow-wrap: anywhere;
}

.project_card_description {
  text-align: left;
  hyphens: auto;
  word-wrap: break-word;
  word-break: break-word;
}

._actionBtn{
  border: 1px solid;
  border-color: var(--md-sys-color-outline);
  border-radius: 16px;
  padding-left:8px;
  padding-right:8px;
}

// .project_card:last-child {
//   border-bottom: 1px solid;
// }

.project_card_inner {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex-grow: 1;
  flex-basis: 0;
}

</style>
